import { useCallback } from 'react'
import { logGtmEvent, LogGTMEventProps } from 'utils/gtm/event/logGtmEvent'
import { getUserDataLayer } from 'utils/gtm/dataLayer/userDataLayer'
import { getPageDataLayer } from 'utils/gtm/dataLayer/pageDataLayer'
import { useRouter } from 'next/router'

type PageView = {
  page?: GTMPageType
  parameters?: LogGTMEventProps['parameters']
}
export function useGtmPageView() {
  const { locale } = useRouter()
  const logPageView = useCallback(
    (props?: PageView | undefined) => {
      const page_type = props?.page || 'other'

      logGtmEvent({
        eventName: 'page_view',
        parameters: {
          user_data: getUserDataLayer(),
          page_data: getPageDataLayer(locale, { page_type }),
          ...props?.parameters
        }
      })
    },
    [locale]
  )

  return { logPageView }
}
