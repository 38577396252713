import { generateClient, rateLimitRetry } from 'api/contentful/utils/client'
import {
  BranchSellingFragment,
  getSdk
} from 'api/contentful/gql/generated/branches'
import { resolveContentfulLocale } from 'sections/blog/articles/BlogArticles.utils'

const branchesClient = generateClient(getSdk)

export async function getContentfulBranch(slug: string, locale: string) {
  return rateLimitRetry(async () => {
    const { branchCollection } = await branchesClient.Branch({
      slug,
      locale: resolveContentfulLocale(locale)
    })

    const items = branchCollection?.items || []

    if (!items.length) return null

    return items[0]
  })
}

export async function getContentfulBranchSlugs() {
  return rateLimitRetry(async () => {
    const { branchCollection } = await branchesClient.Branches()

    return (
      branchCollection?.items
        .map(item => item?.slug)
        .filter((item): item is string => item !== null) || []
    )
  })
}

export async function getContentfulBranchList(locale?: string) {
  return rateLimitRetry(async () => {
    const { branchCollection } = await branchesClient.Branches({ locale })

    return (
      branchCollection?.items.filter(
        (
          item
        ): item is { branchId: string; slug: string; calendlyLink: string } =>
          item !== null && item.branchId != null && item.slug != null
      ) ?? []
    )
  })
}

export async function getContentfulBranchListSelling(locale: string) {
  return rateLimitRetry(async () => {
    const { branchCollection } = await branchesClient.BranchesSelling({
      locale: resolveContentfulLocale(locale)
    })

    return (
      branchCollection?.items.filter(
        (item): item is BranchSellingFragment =>
          item !== null && item.branchId != null
      ) ?? []
    )
  })
}
