import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ButtonTransparent } from 'driverama-core/components/button/Button'
import { layoutGrid } from 'driverama-core/styles/common'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, shadow, zIndex } from 'driverama-core/styles/variables'
import dynamic from 'next/dynamic'
import { ComponentType } from 'react'
import type { GeolocateControlProps } from 'react-map-gl/src/components/geolocate-control'
import type { NavigationControlProps } from 'react-map-gl/src/components/navigation-control'

const GeolocateControl: ComponentType<GeolocateControlProps> = dynamic(() =>
  import('react-map-gl').then(module => module.GeolocateControl)
)
const NavigationControl: ComponentType<NavigationControlProps> = dynamic(() =>
  import('react-map-gl').then(module => module.NavigationControl)
)

export const SContainer = styled.section`
  ${layoutGrid}
`

export const SPwControls = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  width: 100vw;

  padding: ${size(4)};
`

const pwControlsStyles = css`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: ${size(10)};
  height: ${size(10)};

  color: ${color('night-l-100')};
  background-color: ${color('white')};
  border-radius: ${radius('full')};

  svg {
    width: ${size(5)};
    height: ${size(5)};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: ${radius('full')};
    background-color: ${color('night-l-100')};
    mix-blend-mode: color;
  }
`

export const SPwControlsButton = styled(ButtonTransparent)`
  ${pwControlsStyles};
`

export const SPwControlsGeoButton = styled(GeolocateControl)`
  position: relative !important;
  border-radius: ${radius('full')} !important;
  overflow: hidden;

  div {
    button {
      ${pwControlsStyles};
    }
  }
`

export const SMapControls = styled.div<{ bottomPadding?: string }>`
  position: absolute;
  left: 50%;
  bottom: ${({ bottomPadding }) => bottomPadding ?? size(10)};
  transform: translateX(-50%);
  z-index: ${zIndex('order-1')};

  display: flex;

  box-shadow: ${shadow(3)};
  border-radius: ${radius('full')};
  overflow: hidden;

  @media ${media.gt('tablet')} {
    bottom: ${({ bottomPadding }) => bottomPadding ?? size(20)};
  }
`

const mapControlStyles = css`
  position: relative !important;

  div {
    display: flex;
    box-shadow: none !important;

    button {
      width: ${size(12)};
      height: ${size(12)};

      border: none !important;

      span {
        filter: contrast(0);
      }
    }
  }
`

export const SNavControls = styled(NavigationControl)`
  ${mapControlStyles}

  div {
    flex-direction: row-reverse;

    button:first-of-type {
      border-left: 1px solid ${color('night-l-700')} !important;
      border-right: 1px solid ${color('night-l-700')} !important;
    }

    button:last-of-type {
      width: ${size(13)};
      padding-left: ${size(1)};
    }
  }
`

export const SGeoControls = styled(GeolocateControl)`
  ${mapControlStyles}

  div {
    button {
      width: ${size(13)};
      padding-right: ${size(1)};
    }
  }
`
