import { getFormatedHoursList } from 'components/locationElements/item/hours/LocationItemHours.utils'
import { LINKS, mapsLink } from 'constants/links'
import { LovBranch } from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextBody } from 'driverama-core/components/text/Text'
import { getDefaultLocaleForRegion } from 'driverama-core/utils/env'
import IconCross from 'images/icons/IconCrossThin.svg'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SButton,
  SButtons,
  SCloseButton,
  SContainer,
  SDays,
  SDetails,
  SImage,
  SName
} from './MapBranch.styled'

type Props = {
  branch: LovBranch
  slug: string | undefined
  onClose: () => void
}

export function MapBranch({ branch, slug, onClose }: Props) {
  const { t } = useTranslation('core')
  const router = useRouter()

  const days = useMemo(
    () =>
      getFormatedHoursList(
        new Date(),
        branch,
        router.locale || getDefaultLocaleForRegion()
      ),
    [branch, router.locale]
  )

  return (
    <SContainer>
      <SImage src={branch.photoUrl} alt={branch.name} />
      <SDetails>
        <SCloseButton onClick={onClose}>
          <IconCross />
        </SCloseButton>
        <SName size={['small', 'large']}>{branch.name}</SName>
        <TextBody size={['small', 'large']}>{branch.address}</TextBody>

        <Spacer size={4} />

        <SDays>
          {days.map(day => (
            <Fragment key={day.title}>
              <TextBody size={['small', 'large']}>{day.title}</TextBody>
              <SName size={['small', 'large']}>
                {day.closed
                  ? t('location_item_closed')
                  : day.to
                  ? `${day.from} - ${day.to}`
                  : day.from}
              </SName>
            </Fragment>
          ))}
        </SDays>
      </SDetails>

      <SButtons>
        <Spacer size={4} />

        <Link
          href={branch.navigationUrl || mapsLink(branch.lat, branch.lng ?? 0)}
          passHref
        >
          <a target="_blank">
            <SButton as="span" variant="primary">
              {t(`map_open`)}
            </SButton>
          </a>
        </Link>

        {slug && (
          <>
            <Spacer size={2} />

            <Link href={LINKS.branch(slug)} passHref>
              <a target="_blank">
                <SButton as="span" variant="outline">
                  {t(`map_detail`)}
                </SButton>
              </a>
            </Link>
          </>
        )}
      </SButtons>
    </SContainer>
  )
}
