import calculateDistance from '@turf/distance'
import * as turf from '@turf/helpers'
import { GERMANY_CENTER_COORDS } from 'constants/misc'
import { LovBranch } from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { clamp } from 'driverama-core/utils/math'
import { Maybe } from 'driverama-core/utils/types'
import type { ViewportProps } from 'react-map-gl'
import WebMercatorViewport, {
  lngLatToWorld,
  MAX_LATITUDE,
  worldToLngLat
} from 'viewport-mercator-project'

export type Coords = { lat: number; lng: number }
export type CoordBounds = [[number, number], [number, number]]

export function isMapCoords(x: Maybe<Partial<Coords>>): x is Coords {
  return x?.lat != null && x?.lng != null
}

export const getMapBounds = (coordinates: Coords[]) => {
  const maxLat = coordinates.reduce(
    (max, { lat }) => (lat > max ? lat : max),
    Number.MIN_VALUE
  )
  const minLat = coordinates.reduce(
    (min, { lat }) => (lat < min ? lat : min),
    Number.MAX_VALUE
  )
  const maxLng = coordinates.reduce(
    (max, { lng }) => (lng > max ? lng : max),
    Number.MIN_VALUE
  )
  const minLng = coordinates.reduce(
    (min, { lng }) => (lng < min ? lng : min),
    Number.MAX_VALUE
  )

  const southWest = [minLng, minLat]
  const northEast = [maxLng, maxLat]
  return [southWest, northEast] as CoordBounds
}

export const getNearestBranches = (
  branches: LovBranch[],
  coords: Coords,
  limit?: number
) =>
  branches
    .map(branch => ({
      id: branch.id,
      lat: branch.lat,
      lng: branch.lng ?? 0,
      distance: calculateDistance(
        turf.point([branch.lng ?? 0, branch.lat]),
        turf.point([coords.lng, coords.lat]),
        { units: 'kilometers' }
      )
    }))
    .sort((a, b) => a.distance - b.distance)
    .slice(0, limit ?? 1)

export const getNearestLovBranches = (
  branches: LovBranch[],
  coords: Coords,
  limit = 3
): LovBranch[] =>
  branches
    .map(b => ({
      ...b,
      distance: calculateDistance(
        turf.point([b.lng ?? 0, b.lat]),
        turf.point([coords.lng, coords.lat]),
        { units: 'kilometers' }
      )
    }))
    .sort((a, b) => a.distance - b.distance)
    .slice(0, limit)

export const defaultBoundingCoords = {
  lat: GERMANY_CENTER_COORDS.latitude,
  lng: GERMANY_CENTER_COORDS.longitude
}

function convertToPx(
  x: number | (number | string)[],
  size: number,
  defaultValue: number
) {
  if (typeof x === 'number') return x

  if (Array.isArray(x)) {
    return x.reduce<number>((v, x) => {
      if (typeof x === 'number') {
        return v + x
      }

      if (typeof x === 'string' && x.endsWith('%')) {
        const ratio = Number.parseFloat(x.slice(0, -1)) / 100
        return v + size * ratio
      }

      return v + defaultValue
    }, defaultValue)
  }

  return defaultValue
}

export interface MapPadding {
  top: number | (string | number)[]
  bottom: number | (string | number)[]
  left: number | (string | number)[]
  right: number | (string | number)[]
}

export function getMapPaddingObject(
  padding: number | MapPadding = 0,
  width: number,
  height: number
): {
  top: number
  bottom: number
  left: number
  right: number
} {
  if (typeof padding === 'number') {
    return {
      top: convertToPx(padding, height, 0),
      bottom: convertToPx(padding, height, 0),
      left: convertToPx(padding, width, 0),
      right: convertToPx(padding, width, 0)
    }
  }

  return {
    top: convertToPx(padding.top, height, 0),
    bottom: convertToPx(padding.bottom, height, 0),
    left: convertToPx(padding.left, width, 0),
    right: convertToPx(padding.right, width, 0)
  }
}

export function fitBounds(options: {
  width: number
  height: number
  bounds: [[number, number], [number, number]]
  minExtent?: number // 0.01 would be about 1000 meters (degree is ~110KM)
  maxZoom?: number // ~x4,000,000 => About 10 meter extents
  // options
  padding: {
    top: number
    bottom: number
    left: number
    right: number
  }
  offset?: number[]
}): ViewportProps {
  const {
    width,
    height,
    bounds,
    padding,
    minExtent = 0, // 0.01 would be about 1000 meters (degree is ~110KM)
    maxZoom = 24, // ~x4,000,000 => About 10 meter extents
    offset = [0, 0]
  } = options

  const [[west, south], [east, north]] = bounds
  const nw = lngLatToWorld([west, clamp(north, -MAX_LATITUDE, MAX_LATITUDE)])
  const se = lngLatToWorld([east, clamp(south, -MAX_LATITUDE, MAX_LATITUDE)])

  // width/height on the Web Mercator plane
  const size = [
    Math.max(Math.abs(se[0] - nw[0]), minExtent),
    Math.max(Math.abs(se[1] - nw[1]), minExtent)
  ]

  const targetSize = [
    width - padding.left - padding.right - Math.abs(offset[0]) * 2,
    height - padding.top - padding.bottom - Math.abs(offset[1]) * 2
  ]

  // scale = screen pixels per unit on the Web Mercator plane
  const scaleX = targetSize[0] / size[0]
  const scaleY = targetSize[1] / size[1]

  // Find how much we need to shift the center
  const offsetWorldX = (padding.right - padding.left) / 2 / scaleX
  const offsetWorldY = (padding.top - padding.bottom) / 2 / scaleY

  const centerWorld = [
    (se[0] + nw[0]) / 2 + offsetWorldX,
    (se[1] + nw[1]) / 2 + offsetWorldY
  ]

  const [longitude, latitude] = worldToLngLat(centerWorld)
  const zoom = Math.min(maxZoom, Math.log2(Math.abs(Math.min(scaleX, scaleY))))

  return { longitude, latitude, zoom }
}

type FitToViewportOptions = ({ point: Coords } | { bound: Coords[] }) & {
  width: number
  height: number
  zoomPadding?: MapPadding
  detailPadding?: boolean
}

export function fitToViewport(prev: ViewportProps, data: FitToViewportOptions) {
  try {
    const padding = getMapPaddingObject(
      data.zoomPadding,
      data.width,
      data.height
    )

    let newViewport: WebMercatorViewport | undefined

    if ('point' in data) {
      newViewport = new WebMercatorViewport({
        ...(prev as WebMercatorViewport),
        width: data.width,
        height: data.height,
        latitude: data.point.lat,
        longitude: data.point.lng,
        zoom: 12
      })

      if (data.detailPadding) {
        // account for the height of the tooltip
        // which pops out when a branch is selected
        padding.bottom -= 300
      }
    } else if ('bound' in data) {
      newViewport = new WebMercatorViewport({
        ...(prev as WebMercatorViewport),
        width: data.width,
        height: data.height
      }).fitBounds(getMapBounds(data.bound))
    }

    if (newViewport == null) return prev
    return {
      ...newViewport,
      ...fitBounds({
        width: newViewport.width,
        height: newViewport.height,
        bounds: newViewport.getBounds() as CoordBounds,
        padding
      })
    }
  } catch (err) {
    // Instead of computing the padding ourselves
    // We should swallow the error, to make sure the
    // function is stable regardless of width / height
    if (!err.message?.includes('@math.gl/web-mercator')) {
      throw err
    }
  }

  return prev
}
