import { LovBranch } from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { addDays, format, startOfWeek } from 'date-fns'
import { getLocalePackage } from 'driverama-core/utils/intl'

type CalculatedOpeningDay = Record<
  string,
  {
    day: string
    from: string
    to?: string
  }[]
>

export type DaysRange = {
  title: string
  from: string
  to?: string
  closed: boolean
}

function createKey(openingHour: {
  day:
    | 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY'
    | 'SUNDAY'
  from: string
  to?: string | undefined
}) {
  return `${openingHour.from}-${openingHour.to}`
}

function getDays(now: Date, locale: Locale | undefined) {
  const firstDOW = startOfWeek(now)

  const days = Array.from(Array(7)).map((e, i) => ({
    key: format(addDays(firstDOW, i), 'EEEE').toUpperCase(),
    long: format(addDays(firstDOW, i), 'EEEE', { locale }),
    short: format(addDays(firstDOW, i), 'EEE', { locale })
  }))

  return days
}

function isClosed(day: { day: string; from: string; to?: string | undefined }) {
  return day.from === day.to
}

export function getFormatedHoursList(
  now: Date,
  branch: LovBranch,
  locale: string
) {
  const openingHours = branch.openingHours
  const calculated: CalculatedOpeningDay = {}

  let offset = 0
  for (let index = 0; index < openingHours.length; index++) {
    const element = openingHours[index]
    const prevElement = index === 0 ? undefined : openingHours[index - 1]
    const key = createKey(element)
    const prevKey = prevElement ? createKey(prevElement) : undefined

    if (prevElement && prevKey && prevKey !== key) {
      offset++
    }

    if (!calculated[`${key}-${offset}`]) {
      calculated[`${key}-${offset}`] = []
    }

    calculated[`${key}-${offset}`].push(element)
  }

  const hoursList: DaysRange[] = []
  const dayNames = getDays(now, getLocalePackage(locale))

  for (const key in calculated) {
    const element = calculated[key]
    if (element.length === 1) {
      hoursList.push({
        title:
          dayNames.find(dayName => dayName.key === element[0].day)?.long || '',
        closed: isClosed(element[0]),
        from: element[0].from,
        to: element[0].to
      })
    } else {
      const title = `${
        dayNames.find(dayName => dayName.key === element[0].day)?.short || ''
      }-${
        dayNames.find(
          dayName => dayName.key === element[element.length - 1].day
        )?.short || ''
      }`

      hoursList.push({
        title,
        closed: isClosed(element[0]),
        from: element[0].from,
        to: element[0].to
      })
    }
  }

  return hoursList
}
