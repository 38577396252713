import { point } from '@turf/helpers'
import { ReactNode, useEffect, useRef } from 'react'
import Supercluster, { ClusterFeature, PointFeature } from 'supercluster'

import { useCallback } from 'react'

export type ICluster<P extends Supercluster.AnyProps> =
  | PointFeature<P>
  | ClusterFeature<Supercluster.AnyProps>

function isClusterFeature<P>(
  feature: ICluster<P>
): feature is ClusterFeature<Supercluster.AnyProps> {
  return 'cluster' in feature.properties && feature.properties.cluster
}

export function useMapCluster<
  P extends { lat: number; lng?: number | undefined }
>(
  points: P[],
  options?: {
    minZoom?: number
    maxZoom?: number
    radius?: number
    extent?: number
    nodeSize?: number
  }
) {
  const superclusterRef = useRef<Supercluster<P>>()

  const {
    minZoom = 0,
    maxZoom = 16,
    radius = 40,
    extent = 512,
    nodeSize = 64
  } = options ?? {}

  useEffect(() => {
    superclusterRef.current = new Supercluster<P>({
      minZoom,
      maxZoom,
      radius,
      extent,
      nodeSize
    })

    superclusterRef.current.load(
      points.map(item => point([item.lng ?? 0, item.lat ?? 0], item))
    )
  }, [points, minZoom, maxZoom, radius, extent, nodeSize])

  return useCallback((bbox: number[][], zoom?: number) => {
    const [[westLng, southLat], [eastLng, northLat]] = bbox

    const geojson: ICluster<P>[] =
      superclusterRef.current?.getClusters(
        [westLng, southLat, eastLng, northLat],
        Math.floor(zoom ?? 0)
      ) ?? []

    return {
      geojson,
      getLeaves: (clusterId: number) =>
        superclusterRef.current?.getLeaves(clusterId)
    }
  }, [])
}

export function MapClusterGeoJSON<P>(props: {
  geojson: ICluster<P>[]
  cluster: (props: {
    latitude: number
    longitude: number
    cluster: ClusterFeature<Supercluster.AnyProps>
  }) => ReactNode
  pin: (props: {
    latitude: number
    longitude: number
    point: PointFeature<P>
  }) => ReactNode
}) {
  return (
    <>
      {props.geojson.map(feature => {
        const [longitude, latitude] = feature.geometry.coordinates
        if (isClusterFeature(feature)) {
          return props.cluster({ latitude, longitude, cluster: feature })
        }

        return props.pin({ latitude, longitude, point: feature })
      })}
    </>
  )
}
