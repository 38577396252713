import styled from '@emotion/styled'
import { size } from 'driverama-core/styles/spacing'
import { color, radius, shadow } from 'driverama-core/styles/variables'
import { TextBodyMedium } from 'driverama-core/components/text/Text'
import {
  Button,
  ButtonTransparent
} from 'driverama-core/components/button/Button'
import { fadeIn } from 'driverama-core/styles/animations'
import { media } from 'driverama-core/styles/media'

export const SContainer = styled.div`
  position: absolute;
  bottom: ${size(12)};
  left: 50%;
  transform: translateX(-50%);

  display: grid;
  grid-template-columns: ${size(14)} 1fr;
  grid-column-gap: ${size(4)};

  width: ${size(80)};

  padding: ${size(6)};

  background-color: ${color('white')};
  box-shadow: ${shadow(2)};
  border-radius: ${radius('corner-small')};

  animation: 0.25s ${fadeIn} ease-in-out;
  pointer-events: none;

  @media ${media.gt('tablet')} {
    bottom: ${size(22)};
  }
`

export const SImage = styled.img`
  width: 100%;
  height: auto;

  border-radius: ${radius('corner-smallest')};
`

export const SDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const SName = styled(TextBodyMedium)`
  color: ${color('night-d-200')};
`

export const SDays = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: ${size(2)};
`

export const SButtons = styled.div`
  grid-column: span 2;
`

export const SButton = styled(Button)`
  width: 100%;
  height: ${size(8)};

  font-size: 14px;

  pointer-events: all;
`

export const SCloseButton = styled(ButtonTransparent)`
  position: absolute;
  top: ${size(3)};
  right: ${size(3)};

  color: ${color('night-text')};
  pointer-events: all;
`
