import IconPinLarge from 'images/icons/mapPin/IconPinLarge.svg'
import IconPinSmall from 'images/icons/mapPin/IconPinSmall.svg'
import { ReactNode } from 'react'
import { Marker } from '../marker/Marker'
import { SIconWrapper, SNumberWrapper, SWrapper } from './MapPin.styled'

type Props = {
  onClick?: () => void
  size: 'small' | 'large'
  latitude: number
  longitude: number
  count: ReactNode
}

export function MapClusterPin({
  count,
  latitude,
  longitude,
  size,
  onClick
}: Props) {
  const getOffsets = () => {
    switch (size) {
      case 'large':
        return { offsetLeft: -30, offsetTop: -74 }
      case 'small':
        return { offsetLeft: -14, offsetTop: -32 }
      default:
        return {}
    }
  }

  return (
    <Marker latitude={latitude} longitude={longitude} {...getOffsets()}>
      <SWrapper onClick={onClick} isClickable={!!onClick}>
        <SIconWrapper
          colorVariant="secondary"
          as={size === 'small' ? IconPinSmall : IconPinLarge}
        />
        <SNumberWrapper as="span" variant="h4" size={size}>
          {count}
        </SNumberWrapper>
      </SWrapper>
    </Marker>
  )
}
