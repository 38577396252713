import { useQuery } from 'react-query'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { getContentfulBranchList } from 'api/contentful/branches'
import { getDay, isWithinInterval, setHours, setMinutes } from 'date-fns'

export function useContentfulBranchList(locale?: string) {
  return useQuery(
    QUERY_KEYS.branches(locale),
    async () => await getContentfulBranchList(locale)
  )
}

export const isBranchOpen = (
  openingHours: { day: string; from: string; to?: string }[]
) => {
  let todayStr = ''
  switch (getDay(new Date())) {
    case 0:
      todayStr = 'SUNDAY'
      break
    case 1:
      todayStr = 'MONDAY'
      break
    case 2:
      todayStr = 'TUESDAY'
      break
    case 3:
      todayStr = 'WEDNESDAY'
      break
    case 4:
      todayStr = 'THURSDAY'
      break
    case 5:
      todayStr = 'FRIDAY'
      break
    case 6:
      todayStr = 'SATURDAY'
      break
    default:
      break
  }

  const today = openingHours.find(({ day }) => day === todayStr)
  if (!today || !today.to) return false

  try {
    const [fromH, fromM] = today.from.split(':')
    const [toH, toM] = today.to.split(':')
    const start = setMinutes(setHours(new Date(), +fromH), +fromM)
    const end = setMinutes(setHours(new Date(), +toH), +toM)

    return isWithinInterval(new Date(), { start, end })
  } catch {
    return false
  }
}
