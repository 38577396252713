import { MapboxAddress } from 'api/mapbox/mapboxTypes'
import { MapBranch } from 'components/map/branch/MapBranch'
import {
  MapClusterGeoJSON,
  useMapCluster
} from 'components/map/cluster/MapCluster'
import { MapClusterPin } from 'components/map/pin/MapClusterPin'
import { MapPin } from 'components/map/pin/MapPin'
import { HOMEPAGE_MAP_STYLE_LINK } from 'constants/misc'
import {
  BranchListOptions,
  LovBranch,
  useBranchList
} from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { media } from 'driverama-core/styles/media'
import { color } from 'driverama-core/styles/variables'
import IconArrow from 'images/icons/IconArrowSmall.svg'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { MapRef, ViewportProps } from 'react-map-gl'
import { useMeasure, useMedia } from 'react-use'
import { useContentfulBranchList } from 'sections/branch/other/BranchOther.utils'
import { WebMercatorViewport } from 'viewport-mercator-project'
import {
  SGeoControls,
  SMapControls,
  SNavControls,
  SPwControls,
  SPwControlsButton,
  SPwControlsGeoButton
} from './Map.styled'
import {
  defaultBoundingCoords,
  fitToViewport,
  getNearestBranches,
  isMapCoords,
  MapPadding
} from './Map.utils'

const ReactMapGL = dynamic(() => import('components/map/glMap/ReactMapGL'), {
  ssr: false
})

interface Props {
  selectedBranch: LovBranch | undefined
  onBranchSelect: (b: LovBranch | undefined) => void
  selectedAddress: MapboxAddress | undefined
  onAddressSelect: (a: MapboxAddress | undefined) => void
  hideBranches?: boolean
  zoomPadding?: MapPadding
  mobileDragPan?: boolean
  hideBranchDetail?: boolean
  controlsBottomPadding?: string
  numberOfNearestBranches?: number
  iconSize?: 'small' | 'large'
  filterVariant?: 'almond' | 'night'
  pwControls?: boolean
  pwOnBack?: () => void
  className?: string
  queryOptions?: BranchListOptions
}

export function Map({
  hideBranches,
  selectedBranch,
  onBranchSelect,
  selectedAddress,
  onAddressSelect,
  controlsBottomPadding,
  numberOfNearestBranches,
  zoomPadding,
  mobileDragPan,
  hideBranchDetail,
  filterVariant = 'almond',
  pwControls,
  pwOnBack,
  className,
  queryOptions
}: Props) {
  const { t } = useTranslation(['core'])

  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()
  const [viewport, setViewport] = useState<ViewportProps>({ width, height })

  const mapRef = useRef<MapRef>(null)
  const isDesktop = useMedia(media.gt('tablet'))
  const dragPan =
    !isDesktop && !mobileDragPan ? { dragPan: false, touchAction: 'pan-y' } : {}

  const branches = useBranchList(queryOptions)
  const contentfulBranches = useContentfulBranchList()

  const getCluster = useMapCluster(branches.branches, { radius: 50 })
  const bounds = new WebMercatorViewport({
    ...viewport,
    width,
    height
  }).getBounds()

  const geojson = getCluster(bounds, viewport.zoom)

  const onViewportChange = (viewport: ViewportProps) => setViewport(viewport)
  const onNavigate = (currentViewport: ViewportProps) => {
    if (viewport.zoom == null || currentViewport.zoom == null) {
      return
    }

    const viewportStateZoom = viewport.zoom
    const currentViewportZoom = currentViewport.zoom

    const zoomDiff = currentViewportZoom - viewportStateZoom
    const newZoom = viewportStateZoom + zoomDiff * 2

    setViewport({ ...viewport, zoom: newZoom })
  }

  const onGeolocate = ({ coords }: { coords: GeolocationCoordinates }) => {
    onAddressSelect({ lat: coords.latitude, lng: coords.longitude })
  }

  const onPinClick = (branch: LovBranch) => onBranchSelect(branch)

  const onClusterClick = (clusterId: number) => {
    const leaves = geojson.getLeaves(clusterId)
    if (!leaves) return

    const coords = leaves.map(leaf => ({
      lat: leaf.geometry.coordinates[1],
      lng: leaf.geometry.coordinates[0]
    }))

    setViewport(prev =>
      fitToViewport(prev, {
        bound: coords,
        zoomPadding,
        width,
        height
      })
    )
  }

  const renderViewport = useRef<() => void>()

  useEffect(() => {
    renderViewport.current = () => {
      if (isMapCoords(selectedBranch)) {
        setViewport(prev =>
          fitToViewport(prev, {
            point: selectedBranch,
            zoomPadding,
            width,
            height,
            detailPadding: !hideBranchDetail
          })
        )
      } else if (selectedAddress) {
        if (hideBranches) {
          const newViewport = {
            point: selectedAddress,
            zoomPadding,
            width,
            height
          }
          setViewport(prev => fitToViewport(prev, newViewport))
        } else {
          const nearest = getNearestBranches(
            branches.branches,
            selectedAddress,
            numberOfNearestBranches
          )
          const newViewport = {
            bound: [...nearest, selectedAddress],
            zoomPadding,
            width,
            height
          }
          setViewport(prev => fitToViewport(prev, newViewport))
        }
      } else if (branches.branches.length > 0) {
        const points = branches.branches
          .map(({ lat, lng }) => ({ lat, lng }))
          .filter(isMapCoords)

        setViewport(prev =>
          fitToViewport(prev, {
            ...(points.length > 1
              ? { bound: points }
              : { point: points[0] ?? defaultBoundingCoords }),
            zoomPadding,
            width,
            height
          })
        )
      }
    }

    renderViewport.current()
  }, [
    width,
    height,
    branches.branches,
    zoomPadding,
    selectedAddress,
    selectedBranch,
    hideBranches,
    hideBranchDetail,
    numberOfNearestBranches
  ])

  return (
    <>
      <Head>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Head>

      <div
        ref={containerRef}
        className={className}
        css={() =>
          filterVariant === 'night'
            ? `
              background-color: ${color('night-l-700', 1)};
            `
            : `
              background-color: ${color('almond-l-200', 1)};
            `
        }
      >
        {!branches.isLoading && branches.branches && (
          <ReactMapGL
            forwardedRef={mapRef}
            mapStyle={HOMEPAGE_MAP_STYLE_LINK}
            mapboxApiAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
            scrollZoom={false}
            transitionDuration={1200}
            onViewportChange={onViewportChange}
            onLoad={renderViewport.current}
            {...dragPan}
            {...viewport}
            width={width}
            height={height}
            style={{
              mixBlendMode: filterVariant === 'night' ? 'multiply' : 'normal'
            }}
          >
            {pwControls && pwOnBack ? (
              <SPwControls>
                <SPwControlsButton onClick={pwOnBack}>
                  <IconArrow />
                </SPwControlsButton>
                <SPwControlsGeoButton
                  onGeolocate={onGeolocate}
                  showUserLocation={false}
                />
              </SPwControls>
            ) : (
              <SMapControls bottomPadding={controlsBottomPadding}>
                <SNavControls
                  onViewportChange={onNavigate}
                  showCompass={false}
                />

                <SGeoControls
                  onGeolocate={onGeolocate}
                  showUserLocation={false}
                />
              </SMapControls>
            )}

            {mapRef.current && (
              <>
                {!hideBranches && (
                  <MapClusterGeoJSON
                    geojson={geojson.geojson}
                    cluster={({ latitude, longitude, cluster }) => (
                      <MapClusterPin
                        key={`cluster-${cluster.id}`}
                        count={cluster.properties.point_count_abbreviated}
                        latitude={latitude}
                        longitude={longitude}
                        size={isDesktop ? 'large' : 'small'}
                        onClick={() =>
                          onClusterClick(cluster.properties.cluster_id)
                        }
                      />
                    )}
                    pin={({ latitude, longitude, point }) => (
                      <MapPin
                        key={`pin-${point.properties.id}`}
                        latitude={latitude}
                        longitude={longitude}
                        label={point.properties.name}
                        onClick={() => onPinClick(point.properties)}
                        size={isDesktop ? 'large' : 'small'}
                        variant={
                          point.properties.type === 'SUPER_STORE'
                            ? 'superstore'
                            : 'pitstop'
                        }
                        color="secondary"
                      />
                    )}
                  />
                )}
                {selectedAddress && (
                  <MapPin
                    key="home"
                    latitude={selectedAddress.lat}
                    longitude={selectedAddress.lng}
                    label={t('core:map_your_address')}
                    size={isDesktop ? 'large' : 'small'}
                    variant="home"
                    color="secondary"
                  />
                )}
                {selectedBranch && (
                  <MapPin
                    key={selectedBranch.id}
                    latitude={selectedBranch.lat ?? 0}
                    longitude={selectedBranch.lng ?? 0}
                    label={selectedBranch.name || ''}
                    onClick={() => onBranchSelect(undefined)}
                    size={isDesktop ? 'large' : 'small'}
                    variant={
                      selectedBranch.type === 'SUPER_STORE'
                        ? 'superstore'
                        : 'pitstop'
                    }
                    color="primary"
                  >
                    {!hideBranchDetail && (
                      <MapBranch
                        branch={selectedBranch}
                        slug={
                          contentfulBranches.data?.find(
                            b => b.branchId === selectedBranch.id
                          )?.slug
                        }
                        onClose={() => onBranchSelect(undefined)}
                      />
                    )}
                  </MapPin>
                )}
              </>
            )}
          </ReactMapGL>
        )}
      </div>
    </>
  )
}
